import React from "react";
import Navigation from "../../components/navigation";
import Slider from "../../containers/slider";

const HomePage: React.FC = () => {
  return (
    <React.Fragment>
      <Navigation />
      <Slider />
    </React.Fragment>
  );
};

export default HomePage;
