import React from "react";
import logo from "../../assets/logo.png";

const Navigation: React.FC = () => {
  const itemSelectedBorder = () => {
    return (
      <div className="flex w-full">
        <div className="h-1 bg-darkYellow w-full rounded-lg"></div>
        <div className="h-1 w-1 bg-darkYellow rounded-full ml-1"></div>
      </div>
    );
  };
  return (
    <nav className="w-full p-5 flex items-center">
      <div className="w-2/3">
        <img
          src={logo}
          alt="Inovace Developing Service"
          className="w-12 ml-10"
        />
      </div>
      <div className="w-1/3">
        <ul className="list-none flex justify-end items-center">
          <li className="mr-5">
            <span>INICIO</span>
            {itemSelectedBorder()}
          </li>
          {/* <li className="mr-5">
            <span>NOSOTROS</span>
          </li>
          <li className="mr-5">SERVICIOS</li>
          <li className="mr-5">PROCESO</li>
          <li className="mr-5 border-2 border-purple bg-white py-3 px-10 rounded-full">
            CONTACTO
          </li> */}
        </ul>
      </div>
    </nav>
  );
};

export default Navigation;
