import React from "react";
import SliderHome from "../../assets/slider/SliderHome.png";

const Slider: React.FC = () => {
  const handleClicButton = () => {
    window.open("https://instagram.com/inovaceds");
  };
  return (
    <div className="flex lg:flex-row md:flex-row sm:flex-row xs:flex-col-reverse  items-center">
      <div className="w-4/6 xs:w-full lg:p-24 flex flex-col md:p-10 xs:p-5">
        <div className="flex flex-col justify-center">
          <h1 className="text-purple font-extrabold lg:text-6xl md:text-2xl xs:text-3xl xs:text-center lg:text-start md:text-start">
            CONVERTIMOS IDEAS EN PRODUCTOS DIGITALES
          </h1>
          <p className="text-purple text-lg mt-10 md:text-sm">
            Nuestros productos digitales están diseñados para proporcionar
            comodidad, eficiencia y entretenimiento de alta calidad en el mundo
            digital.
          </p>
        </div>
        <div className="mt-10 flex lg:flex-row md:flex-col sm:flex-col xs:flex-col">
          <button
            className="mr-5 text-white border-2 border-t-orange bg-orange py-3 px-10 rounded-full mt-5"
            onClick={handleClicButton}
          >
            Nuestro Trabajo
          </button>
          <button
            className="mr-5 text-orange border-2 border-t-orange bg-white py-3 px-10 rounded-full mt-5"
            onClick={handleClicButton}
          >
            Contacto
          </button>
        </div>
      </div>
      <div className="flex justify-end w-full">
        <img src={SliderHome} alt="Inovace DS" />
      </div>
    </div>
  );
};

export default Slider;
